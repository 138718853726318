import { AppDB } from '@/database/AppDB';
import { ClientInstance, Repositories } from '@/repositories';
import { UserStore } from './UserStore';
import { ConfigStore } from './ConfigStore';
import { DoerStore } from './DoerStore';
import { TimerStore } from './TimerStore';

export function createStore(db: AppDB, repositories: Repositories, http: ClientInstance): StoreInstance {
  const instances: Partial<StoreInstance> = {
    recreateInstances,
  };

  /**
   * Пресоздаст все экземпляры хранилищ.
   * 
   * NOTE: Это необходимо после смены базового URL,
   * т.к. меняется БД, и весь сохраненный кэш и данные
   * @returns 
   */
  function recreateInstances(db: AppDB): StoreInstance {
    const ctx = { db, repositories, http };

    const config = new ConfigStore(ctx);
    const user = new UserStore(ctx);
    const timer = new TimerStore(ctx);
    const doer = new DoerStore({ ...ctx, timer });
    
    timer.activate();

    Object.assign(instances, {
      config,
      user,
      doer,
      timer,
    });

    return instances as StoreInstance;
  }

  return recreateInstances(db);
}

export interface StoreInstance {
  recreateInstances: (db: AppDB) => StoreInstance;
  config: ConfigStore;
  user: UserStore;
  doer: DoerStore;
  timer: TimerStore;
}