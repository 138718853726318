
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    grandTotal: {
      type: [String, Number],
      required: false
    }
  }
});
