import { App, inject, onBeforeUnmount } from 'vue';
import { isPlatform } from '@ionic/vue';
import { initializeApp, FirebaseApp } from 'firebase/app';
import toast from '@/ext/toast';
import config from '@/config';
import type { NotificationData } from '@/composables/notifications';
import mitt from 'mitt';

import {
  capPushNotificationSchemaToNotificationData,
  webFirebaseMessagePayloadToNotificationData
} from '@/helpers/push';

import {
  PushNotifications as CapPushNotifications,
  Token,
  ActionPerformed,
  PushNotificationSchema,
} from '@capacitor/push-notifications';

import {
  getMessaging,
  Messaging,
  getToken as getWebPushToken,
  onMessage,
  isSupported as isWebPushSupported,
} from 'firebase/messaging';

/**
 * Push-токен храниться по данному ключу в localStorage
 */
export const LS_PUSH_TOKEN_KEY = 'push/token';

/**
 * Контекст пушей передается по данному ключу
 */
export const PROVIDE_PUSH_CONTEXT_KEY = 'app_push_context';

/**
 * Режим сборки production
 */
const IS_PRODUCTION_MODE: boolean = (process.env.NODE_ENV === 'production');

export interface PushContextConfig {
  [prop: string]: any;
}

/**
 * События которые могут вызываться в контектсе пуш-сообщений
 */
export type NotificationEvents = {
  notification: NotificationData;
  mergeNotification: NotificationData;

  /**
   * Приложение загружено и ожидает отображение уведомлений
   */
  appInit: void;
};

/**
 * 
 * NOTE: @capacitor/push-notifications - не поддерживат веб-пуши,
 * из-за этого требуется отдельно тащить Firebase app и его веб-пуши.
 * 
 * @returns 
 */
function createPushContext() {
  const isWebPlatform = (false === isPlatform('capacitor'));
  const eventBus = mitt<NotificationEvents>();
  
  let isAppInit = false;
  eventBus.on('appInit', () => isAppInit = true);

  //#region [CapacitorPlugin] -------------------------------------------------
  
  // NOTE: Из-за того, что ответ регистрации вызывается в отдельных
  // событиях, пришлось написать весь этот винегрет.
  let registerPromiseCallback: { resolve: (token: string) => void; reject: (error: any) => void; }|null = null;

  /**
   * Регистрация пушей (получение токена)
   * @returns 
   */
  function capacitorRegister() {
    return new Promise<string>((resolve, reject) => {
      registerPromiseCallback = { resolve, reject };
      CapPushNotifications.register()
        .then(capacitorMergeHistory)
      ;
    });
  }

  /**
   * Добавляет все сообщения в базу данных (innoDB)
   */
  async function capacitorMergeHistory() {
    const { notifications } = await CapPushNotifications.getDeliveredNotifications();

    for (const notification of notifications) {
      const notificationData = capPushNotificationSchemaToNotificationData(notification);
      eventBus.emit('mergeNotification', notificationData);
    }
  }

  /**
   * Правильно отправит события от Capacitor для отображения и сохранения сообщения в истории
   * @param notificationData 
   */
  function capNotificationEmitEvents(notificationData: NotificationData) {
    // fix: Не отображаем и не сохраняем в итории пустое сообщение
    if (!notificationData.title && !notificationData.subtitle &&!notificationData.body) {
      return;
    }

    const emitEvents = () => {
      eventBus.emit('mergeNotification', notificationData);
      eventBus.emit('notification', notificationData);
    };

    if (isAppInit) {
      emitEvents();
    } else {
      // fix: Возникновение события "pushNotificationActionPerformed" происходит еще до инициализации приложения,
      // из-за чего сообщение не может сохраниться в истории и быть отображено
      eventBus.on('appInit', function delayedEvents() {
        emitEvents();
        eventBus.off('appInit', delayedEvents);
      });
    }
  }

  if (false === isWebPlatform) {
    // Токен успешно заркгистрирован, можно получать уведомления
    CapPushNotifications.addListener('registration', (token: Token) => {
      registerPromiseCallback?.resolve(token.value);
      registerPromiseCallback = null;
    });

    // Возникли проблемы при регистрации токена, пуши не будут работать
    CapPushNotifications.addListener('registrationError', (error: any) => {
      registerPromiseCallback?.reject(error);
      registerPromiseCallback = null;
    });

    // Уведомление получено (срабатывает, если приложение открыто в момент получения)
    CapPushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      const notificationData = capPushNotificationSchemaToNotificationData(notification);
      capNotificationEmitEvents(notificationData);
    });

    // Метод вызывается при нажатии на уведомление
    CapPushNotifications.addListener('pushNotificationActionPerformed', (action: ActionPerformed) => {
      const notificationData = capPushNotificationSchemaToNotificationData(action.notification);
      capNotificationEmitEvents(notificationData);
    });
  }

  /**
   * Запрос на получение пушей
   * @returns 
   */
  async function capacitorRequestPermissions() {
    let permStatus = await CapPushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt' || permStatus.receive === 'prompt-with-rationale') {
      const result = await CapPushNotifications.requestPermissions();
      return (result.receive === 'granted');
    }

    return (permStatus.receive === 'granted');
  }
  //#endregion ----------------------------------------------------------------

  //#region [FirebaseMessaging] [Web] -----------------------------------------
  let firebaseApp: FirebaseApp|null = null;
  let webMessaging: Messaging|null = null;
  let swRegister: ServiceWorkerRegistration|undefined;
  /**
   * Регистрация приложения firebase, создание экземпляра Messaging и получение push-токена
   * 
   * @returns 
   */
  async function webRegister() {
    const isSupported = await isWebPushSupported();

    if (false === isSupported) {
      throw new Error('Пуши не поддерживаются данным браузером')
    }

    if (!swRegister && ('serviceWorker' in navigator)) {
      swRegister = await navigator.serviceWorker.register(`${process.env.BASE_URL}firebase-messaging-sw.js`, {
        scope: 'firebase-cloud-messaging-push-scope',
      })
    }

    if (!firebaseApp) {
      firebaseApp = initializeApp(config.firebaseConfig);
    }
    
    if (!webMessaging) {
      webMessaging = getMessaging(firebaseApp);

      /**
       * Сообщения на переднем плане
       */
      onMessage(webMessaging, payload => {
        const notificationData = webFirebaseMessagePayloadToNotificationData(payload);
        eventBus.emit('mergeNotification', notificationData);
        eventBus.emit('notification', notificationData);
      });
    }

    return await getWebPushToken(webMessaging, {
      serviceWorkerRegistration: swRegister,
      vapidKey: config.firebaseVapidKey,
    });
  }

  /**
   * Запрос на получение пушей
   * @returns 
   */
  async function webRequestPermissions() {
    const result = await Notification.requestPermission();
    return (result === 'granted');
  }
  //#endregion ----------------------------------------------------------------

  /**
   * Регистрация пушей
   * @return push-токен
   */
  const register = isWebPlatform ? webRegister : capacitorRegister;

  /**
   * Запрос на получение пушей
   * @returns true - получены разрешения
   */
  const requestPermissions = isWebPlatform ? webRequestPermissions : capacitorRequestPermissions;

  function setToken(token: string) {
    localStorage.setItem(LS_PUSH_TOKEN_KEY, token);
  }

  function getToken(): string|null {
    return localStorage.getItem(LS_PUSH_TOKEN_KEY);
  }

  /**
   * Проверяет, поддерживается ли push-уведомления на данном устройстве
   * 
   * NOTE: В режиме разработки (не production) всегда будет возвращать false
   * @returns 
   */
  async function isSupported() {
    if (isWebPlatform) {
      return IS_PRODUCTION_MODE && await isWebPushSupported();
    }

    return true; // native: iOS, Android
  }

  /**
   * Запрашивает разрешения на получение уведомлений и автоматически регистрирует
   * 
   * @param throwsError если указать true - выбросит исключение в случае ошибки,
   *                    false - выведет сообщение об ошибки (toast)
   * @returns push-token или false
   */
  async function requestPermissionsAndRegister(throwsError = false): Promise<string|false> {
    try {
      const isGranted = await requestPermissions();

      if (isGranted) {
        const pushToken = await register();
        setToken(pushToken);

        return pushToken;
      }

      return isGranted;
    } catch (error: any) {
      if (throwsError) {
        throw error;
      }

      toast.error(JSON.stringify(error), 5000, {
        header: 'Push-token error on registration'
      });

      return false;
    }
  }

  /**
   * Удалить все полученные сообщения
   * 
   * NOTE: Актуально только для мобильных приложений iOS & Android
   */
  const removeAllDelivered = isWebPlatform
    ? (async () => { /* web not supported */})
    : CapPushNotifications.removeAllDeliveredNotifications
  ;

  // Development mode
  if (false === IS_PRODUCTION_MODE) {
    console.log('Push event bus: ', eventBus);

    eventBus.on('notification', notification => {
      console.log('Push-notification: ', notification);
    });

    eventBus.on('mergeNotification', notification => {
      console.log('Notification merge: ', notification);
    });
  }

  return {
    isSupported,
    requestPermissions,
    requestPermissionsAndRegister,
    isWebPlatform,
    setToken,
    getToken,
    register,
    eventBus,
    removeAllDelivered,
  };
}

export type PushContext = ReturnType<typeof createPushContext>;

/**
 * Получить объект для взаимодействия с пушами
 * 
 * @returns 
 */
export function usePush(): PushContext {
  const push = inject<PushContext>(PROVIDE_PUSH_CONTEXT_KEY);

  if (!push) {
    throw new Error('Не инициализирован модуль пушей');
  }

  return push;
}

/**
 * Прикрепляет слушателя push-уведомлений
 * @param handler 
 */
export function onNotification(handler: (notification: NotificationData) => void) {
  const push = usePush();

  push.eventBus.on('notification', handler);

  onBeforeUnmount(() => {
    push.eventBus.off('notification', handler);
  });
}

export default {
  install(app: App /*, config: PushContextConfig = {}*/) {
    const push = createPushContext();
    app.provide(PROVIDE_PUSH_CONTEXT_KEY, push);
  }
}