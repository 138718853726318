import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

import Login from '@/views/Login.vue';
import DoerProvidedServicesStat from '@/views/DoerProvidedServicesStat.vue';
import Dev from '@/views/Dev.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: Login,
  },

  {
    path: '/doer/provided-services',
    name: 'doer_provided_services',
    component: DoerProvidedServicesStat,
  },

  //#region Development
  {
    path: '/dev',
    name: 'dev',
    component: Dev,
    meta: {}
  },
  //#endregion

  // {
  //   path: '/:catchAll(.*)',
  //   component: Page404,
  // },
  // {
  //   path: '/404',
  //   name: 'page404',
  //   component: Page404,
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
