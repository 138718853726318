import { Dictionary, invert, toLower } from 'lodash';
import { CarNumberTranslitDictionary } from '@/repositories/Models/Config';

export const CAR_NUMBER_REGEXP_CITIZEN = /^[abekmhopctyx]{1}[\d]{3}[abekmhopctyx]{2}[\d]{2,3}$/;
export const CAR_NUMBER_REGEXP_MILITARY = /^[\d]{4}[abekmhopctyx]{2}[\d]{2,3}$/;
export const CAR_NUMBER_REGEXP_POLICE = /^[abekmhopctyx]{1}[\d]{4}[\d]{2,3}$/;
export const CAR_NUMBER_REGEXP_TAXI = /^[abekmhopctyx]{2}[\d]{3}[\d]{2,3}$/;
export const CAR_NUMBER_REGEXP_DIPLOMATICAL_CD = /^[\d]{3}cd[\d][\d]{2,3}$/;
export const CAR_NUMBER_REGEXP_DIPLOMATICAL_D = /^[\d]{3}d[\d]{3}[\d]{2,3}$/;
export const CAR_NUMBER_REGEXP_DIPLOMATICAL_T = /^[\d]{3}t[\d]{3}[\d]{2,3}$/;

/**
 * Специальный словарь для транслитерации номеров автомобией (ru -> en)
 */
export const CAR_NUMBER_TRANSLIT_CHARS: Dictionary<string> = {
  // '<ru>': '<en>'
  'а': 'a',
  'в': 'b',
  'е': 'e',
  'к': 'k',
  'м': 'm',
  'н': 'h',
  'о': 'o',
  'р': 'p',
  'с': 'c',
  'т': 't',
  'у': 'y',
  'х': 'x',
};

/**
 * Специальный словарь для транслитерации номеров автомобией (en -> ru)
 */
export const CAR_NUMBER_TRANSLIT_INVERT = invert(CAR_NUMBER_TRANSLIT_CHARS);

/**
 * Обработка номера автомобиля
 * 
 * Алгоритм:
 *  1. в нижний регистр ->
 *  2. транслитерация русских символов в английские ->
 *  3. удаление символов, которые не могут присутствовать в номере.
 */
export function prepareCarNumber(carNumber: string, translitChars?: CarNumberTranslitDictionary) {
  let preparedNumber = toLower(carNumber);

  translitChars = translitChars || CAR_NUMBER_TRANSLIT_CHARS;

  // Транслитерация русских букв в английские
  let translitNumber = '';
  for (let i = 0; i < preparedNumber.length; ++i) {
    translitNumber += translitChars[preparedNumber[i]] !== undefined
      ? translitChars[preparedNumber[i]]
      : preparedNumber[i];
  }

  // Удадение лишних символов
  preparedNumber = translitNumber.replace(/[^a-z\d]/g, '');
  
  return preparedNumber;
}

/**
 * Заменяет в номере латинкские символы на кирилические
 * 
 * @deprecated
 * 
 * @param carNumber 
 * @returns 
 */
export function carNumberTranslitEnToRu(carNumber: any): string {
  if (typeof carNumber !== 'string') return '';

  carNumber = carNumber.toLowerCase();

  let translitNumber = '';
  for (let i = 0; i < carNumber.length; ++i) {
    translitNumber += CAR_NUMBER_TRANSLIT_INVERT[carNumber[i]] !== undefined
      ? CAR_NUMBER_TRANSLIT_INVERT[carNumber[i]]
      : carNumber[i];
  }

  return translitNumber;
}

/**
 * Транслитерация номера из латиницы
 * 
 * @param carNumber 
 * @param translitCharsInvert словарь для транслитерации
 * @returns 
 */
export function carNumberTranslitLatinToLang(carNumber: any, translitCharsInvert: CarNumberTranslitDictionary): string {
  if (typeof carNumber !== 'string') return '';

  carNumber = toLower(carNumber);

  let translitNumber = '';
  for (let i = 0; i < carNumber.length; ++i) {
    translitNumber += translitCharsInvert[carNumber[i]] !== undefined
      ? translitCharsInvert[carNumber[i]]
      : carNumber[i];
  }

  return translitNumber;
}