
import { computed, defineComponent } from 'vue'
import { carNumberTranslitEnToRu } from '@/utils/car';

export default defineComponent({
  props: {
    carNumber: {
      type: String,
      required: true,
    }
  },
  setup(props) {
    const carNumberFormatted = computed(() => {
      return carNumberTranslitEnToRu(props.carNumber);
    });

    return {
      carNumberFormatted,
    };
  }
});
