import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "app-header-for-doer__title" }
const _hoisted_2 = { class: "cd-smile-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_app_header = _resolveComponent("app-header")!

  return (_openBlock(), _createBlock(_component_app_header, { class: "app-header-for-doer" }, {
    start: _withCtx(() => [
      _createElementVNode("div", {
        class: "app-header-for-doer__id",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toDevPage && _ctx.toDevPage(...args)))
      }, "#" + _toDisplayString(_ctx.doerId), 1)
    ]),
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1)
    ]),
    end: _withCtx(() => [
      _createVNode(_component_ion_button, {
        fill: "clear",
        class: "app-header-for-doer__smile-btn",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('open-emoji')))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_ion_icon, { src: _ctx.moodIcon }, null, 8, ["src"])
          ])
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }))
}