import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = { class: "c-form-group" }
const _hoisted_3 = { class: "mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cid_input = _resolveComponent("cid-input")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_c_divider = _resolveComponent("c-divider")!
  const _component_network_indicator = _resolveComponent("network-indicator")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('components.app_settings.label_input_cid')), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_cid_input, {
        class: "core-input",
        modelValue: _ctx.apiUrl,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.apiUrl) = $event))
      }, null, 8, ["modelValue"]),
      (_ctx.isDifferentApiUrls)
        ? (_openBlock(), _createBlock(_component_ion_button, {
            key: 0,
            disabled: !_ctx.validApiUrl,
            onClick: _ctx.applyBaseUrl,
            fill: "clear",
            color: "transparent",
            class: "ion-no-padding"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('components.app_settings.btn_apply_cid')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_c_divider),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('components.app_settings.label_network_speed')), 1),
    _createVNode(_component_network_indicator),
    _createVNode(_component_c_divider, { class: "mb-0" }),
    _createVNode(_component_ion_button, {
      fill: "clear",
      color: "transparent",
      class: "ion-no-padding",
      href: _ctx.supportChatUrl,
      target: "_blank",
      onClick: _ctx.contactSupport
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('components.app_settings.btn_support_chat')), 1)
      ]),
      _: 1
    }, 8, ["href", "onClick"])
  ]))
}