// PROD: 88820396   |   62dd09c8-14d7-4b36-b960-4a103d20afa0
// TEST: 85744157   |   20067316-f372-44a1-9177-a10283b11fbb

export default {
  /** Ключ от App Метрики (яндекс) */
  appMetricaApiKey: '62dd09c8-14d7-4b36-b960-4a103d20afa0',

  /** Идентификатор Яндекс.Метрики */
  yandexMetricaId: 0,

  /** Данные для инициализации firebase application */
  firebaseConfig: {
    apiKey: "AIzaSyAODo_rRUA9dYVr07pPQyKxEsvxdklSWQQ",
    authDomain: "core12-doer.firebaseapp.com",
    projectId: "core12-doer",
    storageBucket: "core12-doer.appspot.com",
    messagingSenderId: "432842027689",
    appId: "1:432842027689:web:c1fcbf1c338d4df217efb8"
  },

  firebaseVapidKey: 'BIHEYlBrlZtmSywiecMZrkCLhCKj5RWerDwH3x0wME8YoM-bR8ZZcQrO7q6m24Rf6CWD-N22RB7vFcCA4FUhhrE',
}