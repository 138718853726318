import { MetricaPluginAdapter } from '@/composables/useMetrica';
import config from '@/config';
import { isPlatform } from '@ionic/core';
import { get } from 'lodash';
import { isOffline } from '@/helpers/network';

export interface YMInitParams {
  accurateTrackBounce?: boolean;
  childIframe?: boolean;
  clickmap?: boolean;
  defer?: boolean;
  ecommerce?: boolean;
  params?: Array<any>|{[key: string]: any};
  userParams?: {[key: string]: any};
  trackHash?: boolean;
  trackLinks?: boolean;
  trustedDomains?: Array<string>;
  type?: number;
  webvisor?: boolean;
  triggerEvent?: boolean;
}

export type YMEventName = 'init'|string;
export type YMParams = YMInitParams;
export type YM = (id: number, evName: YMEventName, params?: YMParams) => void;

declare interface SubParams {
  callback?: ()=>void,
  ctx?: any,
  params?: {
    order_price?:number,
    currency?: string
  },
  referer?: string,
  title?: string
}

export declare class YaMetrika {
  public addFileExtension(vals: string|Array<string>): void;
  public extLink(url: string, options?: SubParams): void;
  public file(url: string, options?: SubParams): void;
  public getClientID(callback: (clientID: string) => void): void;
  public hit(url: string, options?: SubParams): void;
  public notBounce(options?: {callback: () => void, ctx?: any}): void;
  public params(parameters: Array<any>|{[key: string]: any}): void;
  public reachGoal(target: string, params?: { order_price?: number, currency?: string }, callback?: () => void, ctx?: any): void;
  public replacePhones(): void;
  public setUserID(userID: string): void;
  public userParams(parameters: {[key: string]: any}): void;
}

/**
 * Инициализация скрипта яндекс метрики
 * 
 * @param onload 
 * @param err 
 * @returns 
 */
export function appendScript(onload?: (ym: YM) => void, err?: OnErrorEventHandler): YM {
  const w = (window as any);

  w.ym = w.ym || function (...params: any) {
    (w.ym.a = w.ym.a || []).push(params);
  };

  w.ym.l = Date.now();

  const head = document.head || document.getElementsByTagName('head')[0];

  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://mc.yandex.ru/metrika/tag.js';
  
  head.appendChild(script);

  if (onload) {
    script.onload = () => onload(w.ym);
  }

  if (err) {
    script.onerror = err;
  }

  return w.ym;
}

export function getYM(): YM {
  return get(window, 'ym', null);
}

export function getYaMetrika(id: number): YaMetrika|null {
  return get(window, `yaCounter${id}`, null);
}

export const YandexMetricaAdapter: MetricaPluginAdapter = {
  init({ onEvent }) {
    // В моб приложении яндекс.метрика запускаться не должна
    if (isPlatform('capacitor') || isPlatform('cordova')) return;

    if (!config.yandexMetricaId) {
      console.error('Не указан идентияикатор яндекс метрики');
      return;
    }

    appendScript()(config.yandexMetricaId, 'init', {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
      trackHash: true,
      triggerEvent: true,
    });

    let yaMetrika: YaMetrika|null = null;
    document.addEventListener(`yacounter${config.yandexMetricaId}inited`, () => {
      yaMetrika = getYaMetrika(config.yandexMetricaId);
      yaMetrika?.reachGoal('app/launch');
    });

    onEvent(ev => {
      if (!yaMetrika || isOffline()) return;
      yaMetrika.reachGoal(ev.name, ev.params);
    });
  }
}