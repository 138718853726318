import { UnwrapRef, reactive, readonly, ComputedRef, computed } from 'vue';
import { BaseStore, BaseStoreContext } from './BaseStore';
import type {
  ProvidedServiceArchiveStatQuery,
  DoerProvidedServiceStatItem,
  DoerMoodResponse,
  DoerMoodBodyRequest,
} from '@/repositories/Models/Doer';
import moment from 'moment';
import { parseInt } from 'lodash';
import { TimerStore } from './TimerStore';

export interface DoerStatisticState {
  items: DoerProvidedServiceStatItem[];
  total: number;
}

export interface DoerMoodState {
  prevDate: Date|null;
  nextDate: Date|null;
  mood: number;
}

export interface DoerStoreContext extends BaseStoreContext {
  timer: TimerStore;
}

export class DoerStore extends BaseStore {
  readonly stat: UnwrapRef<DoerStatisticState>;
  readonly mood: UnwrapRef<DoerMoodState>;
  readonly isForsedMood: ComputedRef<boolean>;

  constructor(ctx: DoerStoreContext) {
    super(ctx);

    this.stat = reactive({
      items: [],
      total: 0,
    });

    this.mood = reactive({
      prevDate: null,
      nextDate: null,
      mood: 0,
    });

    this.isForsedMood = computed(() => {
      // Если nextDate == null, то мойщик еще даже не начинал работу => оценивать не нужно
      if (!this.mood.nextDate) {
        return false;
      }

      return moment(ctx.timer.time.value)
        .isSameOrAfter(this.mood.nextDate);
    });
  }

  /**
   * Получаем информацию о выполненных заказах
   * @param params параметры запроса
   * @returns 
   */
  async getVisitsArchiveStat(params: ProvidedServiceArchiveStatQuery) {
    //#region Cache [disabled]
    // const cacheKey = ['provided_service_archive_stat', ...prepareParams(params)];
    // TODO: Подумать над правильным кэшированием, в противном случае,
    // данные будут накапливаться и забивать память
    // const cache = await this.cacheQuery(cacheKey, async () => {
    //   const { data } = await this.repositories.doer.getProvidedServiceArchiveStat(params);
    //   return data;
    // }, 0);
    
    // this.stat.items = cache.data.items;
    // this.stat.total = cache.data.total;
    //#endregion

    const { data } = await this.repositories.doer.getProvidedServiceArchiveStat(params);
    this.stat.items = data.items;
    this.stat.total = data.total;

    return readonly(this.stat);
  }

  /**
   * Получить информацию о текущем настроении и дате когда можно будет оценить еще раз
   * @returns 
   */
  async getMood() {
    const cache = await this.cacheQuery(['mood'], async () => {
      const { data } = await this.repositories.doer.getMood();
      return data;
    }, 0);

    Object.assign(this.mood, this.prepareMoodResponse(cache.data));

    return readonly(this.mood);
  }

  /**
   * Отправить настроение за смену
   * 
   * @param body 
   * @returns 
   */
  async setMood(body: DoerMoodBodyRequest) {
    if (!this.isOnline) {
      throw new Error('Нельзя отправить настроение в оффлайн режиме');
    }

    // NOTE: кэш и возвращаемые данные теже, что и у GET
    const cache = await this.cacheQuery(['mood'], async () => {
      const { data } = await this.repositories.doer.postMood(body);
      return data;
    }, 0);

    Object.assign(this.mood, this.prepareMoodResponse(cache.data));

    return readonly(this.mood);
  }

  /**
   * Правильно обработает и преобразует данные ответа
   * 
   * @param res 
   * @returns 
   */
  protected prepareMoodResponse(res: DoerMoodResponse): DoerMoodState {
    const prevDate = res.prevDate ? moment(res.prevDate).toDate() : null;
    const nextDate = res.nextDate ? moment(res.nextDate).toDate() : null;
    const mood = parseInt(res.mood);

    return { prevDate, nextDate, mood };
  }
}