
import { defineComponent, PropType, ref } from 'vue';
import CollapseTransition from '@/components/core/CollapseTransition.vue';
import { DoerProvidedServiceStatItem } from '@/repositories/Models/Doer';

export default defineComponent({
  components: {
    CollapseTransition,
  },

  props: {
    info: {
      type: Object as PropType<DoerProvidedServiceStatItem>,
      required: true,
    }
  },

  setup() {
    const expanded = ref(false);

    function toogleExpand() {
      expanded.value = !expanded.value;
    }

    return {
      toogleExpand,
      expanded,
    };
  }
});
