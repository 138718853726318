
import { defineComponent, PropType } from "vue"
import CSpinner from './CSpinner.vue'
import CErrorBlock from './CErrorBlock.vue'
import { errorToMessageString, UNKNOW_ERROR_MESAGE } from '@/helpers/error'
import { isEmpty } from "lodash"
import { isOnline } from "@/helpers/network"
import { isClientError } from "@/repositories"

export type ActionFunction = () => Promise<any>;

export default defineComponent({
  emits: ['response-error'],
  components: {
    CSpinner,
    CErrorBlock,
  },
  props: {
    loadingText: {
      type: String as PropType<string>,
    },
    firstLoad: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    loadindHideContent: {
      type: Boolean as PropType<boolean>,
      default: true
    },
    action: {
      type: Function as PropType<ActionFunction>,
      required: true,
    },
  },

  data() {
    return {
      loading: false as boolean,
      errorMessage: null as any,
    };
  },

  computed: {
    hasErrors(): boolean {
      return !isEmpty(this.errorMessage);
    },
    contentHide(): boolean {
      return (this.loadindHideContent && this.loading)
        || this.hasErrors;
    }
  },

  mounted() {
    if (this.firstLoad) this.load();
  },

  methods: {
    async load() {
      try {
        this.errorMessage = null;
        this.loading = true;

        const qp = this.action();
        if (!(qp instanceof Promise)) {
          throw new Error('Функция action для CContentLoading должна быть асинхронной');
        }

        await qp; // await query
      } catch (e) {
        const data = isClientError(e)
          ? e.response?.data
          : e;

        if (!data) {
          this.errorMessage = isOnline()
            ? UNKNOW_ERROR_MESAGE
            : 'Возникла ошибка сети. <br> Проверьте подключение к интрнету и повторите попытку.';
          
        } else {
          this.errorMessage = errorToMessageString(data);
        }

        this.$emit('response-error', e);
      } finally {
        this.loading = false;
      }
    },

    reload() {
      this.load();
    }
  }
});
