
import { computed, defineComponent, nextTick, onMounted, shallowRef, onActivated, ref } from 'vue';
import CCalendarMounth from './CCalendarMounth.vue';
import moment, { Moment } from 'moment';
import { throttle } from 'lodash';
import { useModelSync } from '@/composables/useModelSync';
import VirtualScrollBlock from '../VirtualScrollBlock/VirtualScrollBlock.vue';
import { useScrollInfinite, ScrollEventCtx } from '@/components/core/VirtualScrollBlock/define';

export interface CalendarProps {
  mounth: number;
  year: number;

  /** Указатель, что это текущий месяц */
  currentMounth: boolean;

  /** Указатель на текущий point */
  currentPoint: boolean;
}

const INFINITE_CALENDAR_MOBILE_RENDER_COUNT = 5;
const INFINITE_CALENDAR_EDGE_Y_OFFSET_PX = 500;

export default defineComponent({
  components: { CCalendarMounth, VirtualScrollBlock },
  emits: ['calendar-scroll'],
  props: {
    modelValue: {
      type: null,
    },
    startPoint: {
      type: null,
    },
    isBeetween: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, { emit }) {
    const currentPoint = shallowRef(moment(props.startPoint));
    const { innerValue } = useModelSync(props, 'modelValue');
    const { scrollTop, wheelTransition, containerEl, scrollContentEl, updateSilentlyScrollTop } = useScrollInfinite();
    const now = moment();
    const opacity = ref(0);

    //#region Generate mounth calendars on scroll
    function generateCalendarsPropsList(date: Moment) {
      let calendarsProps: CalendarProps[] = [];
      const start = date.clone().add(-INFINITE_CALENDAR_MOBILE_RENDER_COUNT, 'month');
      const end = date.clone().add(INFINITE_CALENDAR_MOBILE_RENDER_COUNT, 'month');

      for (let curr = start.clone(); curr.isSameOrBefore(end); curr.add(1, 'month')) {
        calendarsProps.push({
          mounth: curr.month(),
          year: curr.year(),
          currentMounth: now.isSame(curr, 'date'),
          currentPoint: currentPoint.value.isSame(curr, 'date'),
        });
      }

      return calendarsProps;
    }

    const calendarsMounts = computed(() => generateCalendarsPropsList(currentPoint.value));

    /** Сохраняем скролл, чтобы при активации восстановить прокрутку */
    let savedScrollTop = 0;

    /**
     * Элемент относительно которого определяем смещения новго контента.
     * Так-же является lock значением (чтобы событие scroll не срабатывало в момент генерации новых элементов)
     */
    let watchChangeOffsetTopEl: HTMLDivElement|null = null;

    /**
     * При скролле добавляем/удаляем лишние месяцы
     * с корректировкой скролла в случае добавления.
     */
    function onScroll(ctx: ScrollEventCtx) {
      if (watchChangeOffsetTopEl) return;

      const containerBounding = ctx.containerEl.getBoundingClientRect();
      const scrollContentBounding = ctx.scrollContentEl.getBoundingClientRect();

      const offsetTop = -(scrollContentBounding.top - containerBounding.top);
      const offsetBottom = (scrollContentBounding.bottom - containerBounding.bottom);

      if (offsetTop < INFINITE_CALENDAR_EDGE_Y_OFFSET_PX) {
        currentPoint.value = currentPoint.value.clone().add(-INFINITE_CALENDAR_MOBILE_RENDER_COUNT, 'month');
        watchChangeOffsetTopEl = ctx.scrollContentEl.querySelector('.c-infinity-calendar__mounth:first-child') as HTMLDivElement;
      } else if (offsetBottom < INFINITE_CALENDAR_EDGE_Y_OFFSET_PX) {
        currentPoint.value = currentPoint.value.clone().add(INFINITE_CALENDAR_MOBILE_RENDER_COUNT, 'month');
        watchChangeOffsetTopEl = ctx.scrollContentEl.querySelector('.c-infinity-calendar__mounth:last-child')  as HTMLDivElement;
      }

      let lastOffsetTop = 0;
      let dOffsetTop = 0;

      if (watchChangeOffsetTopEl) {
        lastOffsetTop = watchChangeOffsetTopEl.getBoundingClientRect().top;

        /**
         * NOTE: На десктопе, при прокрутке колесиком может возникнуть эффект резкого перемещения.
         * Это возникает из-за того, что перед большим смещением холста (для бесконечной прокрутки),
         * анимация отключается.
         * 
         * Как сместить холст и не нарушить transition анимацию, решения не нашел.
         */
        wheelTransition.value = false;
      }

      nextTick(() => {
        if (watchChangeOffsetTopEl) {
          const newOffsetTop = watchChangeOffsetTopEl?.getBoundingClientRect().top || 0;
          dOffsetTop = (newOffsetTop - lastOffsetTop);

          updateSilentlyScrollTop(scrollTop.value + dOffsetTop);
        }

        emit('calendar-scroll', {
          dOffsetTop: dOffsetTop,
          scrollTop: ctx.scrollTop,
        });

        savedScrollTop = scrollTop.value;
        watchChangeOffsetTopEl = null;
      });
    }

    const onScrollTrottle = throttle(onScroll, 100);
    //#endregion

    //#region Correct scrollTop
    /**
     * Выравниваем по центру календарь на который указывает currentPoint
     */
    function currentPointCenterAlign() {
      if (!scrollContentEl.value || !containerEl.value) return;
      const currentCalendarEl: HTMLDivElement|null = scrollContentEl.value.querySelector('.c-infinity-calendar__mounth--point');
      if (!currentCalendarEl) return;

      const calendarBounding = currentCalendarEl.getBoundingClientRect();
      const containerBounding = containerEl.value.getBoundingClientRect();

      const absoluteOffsetTop = (containerBounding.height - calendarBounding.height) / 2;
      const newScrollTop = calendarBounding.top - (-scrollTop.value) - containerBounding.top - absoluteOffsetTop;

      scrollTop.value = newScrollTop;
      savedScrollTop = scrollTop.value;
    }

    /**
     * Восстанавливаем позицию скролла из ранее сохраненного значения
     */
    function restoreScroll() {
      scrollTop.value = savedScrollTop;
    }

    onMounted(() => {
      setTimeout(() => {
        currentPointCenterAlign();
        opacity.value = 1;
      }, 150); // fix: без задержки не успевает построить DOM элементы
    });

    // keep-alive restore scroll position
    onActivated(restoreScroll);
    //#endregion

    return {
      calendarsMounts,
      onScrollTrottle,
      innerValue,
      currentPointCenterAlign,
      opacity,
    };
  },
});
