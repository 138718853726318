
import { modalController } from '@ionic/vue';
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  emits: ['dismiss', 'click-title'],

  props: {
    emitModalDismiss: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    tag: {
      type: null,
      default: 'div'
    }
  },

  setup(props, { emit }) {
    function emitClose(event: Event) {
      event.preventDefault();

      if (props.emitModalDismiss) {
        modalController.dismiss();
      }

      emit('dismiss');
    }

    return {
      emitClose,
    };
  }
});
