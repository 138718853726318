import { BaseRepository, AsyncResponse} from './BaseRepository';
import { BaseSuccessResponse } from './Models/Base';
import {
  UserCollectionQuery,
  CurrentUserResponse,
  UserCollectionResponse,
} from './Models/User';

export class UserRepository extends BaseRepository {
  getCurrent(): AsyncResponse<CurrentUserResponse> {
    return this.client.get('myself');
  }

  logout(): AsyncResponse<BaseSuccessResponse> {
    return this.client.get('managers/logout');
  }

  getUsersCollection(params: UserCollectionQuery = {}): AsyncResponse<UserCollectionResponse> {
    return this.client.get('users', { params });
  }
}
