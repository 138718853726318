import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "core-content-loading" }
const _hoisted_2 = { class: "core-content-loading__content" }
const _hoisted_3 = { class: "core-content-loading__error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_error_block = _resolveComponent("c-error-block")!
  const _component_c_spinner = _resolveComponent("c-spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps({ loading: _ctx.loading, load: _ctx.load })))
    ], 512), [
      [_vShow, !_ctx.contentHide]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "error", _normalizeProps(_guardReactiveProps({ reload: _ctx.reload, errorMessage: _ctx.errorMessage })), () => [
        _createVNode(_component_c_error_block, {
          message: _ctx.errorMessage,
          onReload: _cache[0] || (_cache[0] = ($event: any) => (_ctx.reload()))
        }, null, 8, ["message"])
      ])
    ], 512), [
      [_vShow, _ctx.hasErrors]
    ]),
    _renderSlot(_ctx.$slots, "spinner", _normalizeProps(_guardReactiveProps({ loading: _ctx.loading, loadingText: _ctx.loadingText })), () => [
      _withDirectives(_createVNode(_component_c_spinner, {
        class: "mt-4 mb-3",
        "loading-text": _ctx.loadingText
      }, null, 8, ["loading-text"]), [
        [_vShow, _ctx.loading]
      ])
    ])
  ]))
}