import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, normalizeStyle as _normalizeStyle, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_calendar_mounth = _resolveComponent("c-calendar-mounth")!
  const _component_virtual_scroll_block = _resolveComponent("virtual-scroll-block")!

  return (_openBlock(), _createBlock(_component_virtual_scroll_block, {
    class: "c-infinity-calendar",
    onScroll: _ctx.onScrollTrottle,
    style: _normalizeStyle({ opacity: _ctx.opacity })
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.calendarsMounts, (mounthInfo) => {
        return (_openBlock(), _createBlock(_component_c_calendar_mounth, {
          key: `${mounthInfo.mounth}_${mounthInfo.year}`,
          mounth: mounthInfo.mounth,
          year: mounthInfo.year,
          "is-beetween": _ctx.isBeetween,
          modelValue: _ctx.innerValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event)),
          class: _normalizeClass({
        'c-infinity-calendar__mounth': true,
        'c-infinity-calendar__mounth--current': mounthInfo.currentMounth,
        'c-infinity-calendar__mounth--point': mounthInfo.currentPoint,
      })
        }, null, 8, ["mounth", "year", "is-beetween", "modelValue", "class"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["onScroll", "style"]))
}