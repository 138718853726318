import { inject, App } from 'vue';
import { AppEventBus } from './onEventsApp';
import { deviceReady } from '@/helpers/deviceready';

/** Название базового события для прослушивания событий метрик */
export const METRICA_EVENT_KEY = 'metrica/event';

export interface Metrica {
  emitEvent(name: string, params?: Record<string, any>): void;
}

export interface EventMetrica {
  name: string;
  params?: Record<string, any>;
}

export function useMetrica(): Metrica {
  const metrica = inject<Metrica>('metrica');

  if (!metrica) {
    throw new Error('Базовый плагин метрики не активирован');
  }

  return metrica;
}

//#region Plugin
export type MetricaPluginEventHandler = (ev: EventMetrica) => void;

export interface MetricaPluginAdapterInitContext {
  app: App;
  bus: AppEventBus;
  onEvent(handler: MetricaPluginEventHandler): void;
}

export interface MetricaPluginAdapter {
  init(ctx: MetricaPluginAdapterInitContext): void;
}

export interface MetricaPluginConfig {
  adapters?: MetricaPluginAdapter[];
}

export const MetricaPlugin = {
  install(app: App, config: MetricaPluginConfig = {}) {
    const initContext: MetricaPluginAdapterInitContext = {
      app,

      get bus(): AppEventBus {
        return app.config.globalProperties.$appEventBus;
      },

      onEvent(handler) {
        return initContext.bus.on(METRICA_EVENT_KEY, handler);
      },
    };

    const metricaInstance: Metrica = {
      emitEvent(name, params) {
        return initContext.bus.emit(METRICA_EVENT_KEY, { name, params } as EventMetrica);
      }
    };

    app.config.globalProperties.$metrica = metricaInstance;
    app.provide('metrica', metricaInstance);

    function initAdapters() {
      if (!config.adapters) return;
      if (!initContext.bus) {
        return console.log('Не удалось получить шину событий приложения для инициализации адаптеров метрик');
      }

      for (const metricaAdapter of config.adapters) {
        try {
          metricaAdapter.init(initContext);
        } catch (e) {
          console.error('Ошибка инициализации адаптера метрики', e);
        }
      }
    }

    deviceReady(initAdapters);
  }
};

export default MetricaPlugin;
//#endregion Plugin