import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!

  return (_openBlock(), _createBlock(_component_ion_header, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toolbar, { class: "header-titlebar" }, {
        default: _withCtx(() => [
          (_ctx.hideBackButton == false)
            ? (_openBlock(), _createBlock(_component_ion_buttons, {
                key: 0,
                class: "header-back-buttons",
                slot: "start"
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "start", {}, () => [
                    _createVNode(_component_ion_back_button)
                  ])
                ]),
                _: 3
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_title, null, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "title", _normalizeProps(_guardReactiveProps({ finalTitle: _ctx.finalTitle })), () => [
                _createTextVNode(_toDisplayString(_ctx.finalTitle), 1)
              ])
            ]),
            _: 3
          }),
          (_ctx.$slots.end)
            ? (_openBlock(), _createBlock(_component_ion_buttons, {
                key: 1,
                slot: "end"
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "end")
                ]),
                _: 3
              }))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "toolbar-after")
        ]),
        _: 3
      }),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }))
}