import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["c-progress-bar", { [`ion-color-${_ctx.color}`]: !!_ctx.color }])
  }, [
    _createElementVNode("div", {
      class: "c-progress-bar__progress",
      style: _normalizeStyle({ width: `${_ctx.progressWidth}%` })
    }, null, 4)
  ], 2))
}