import { BaseRepository, AsyncResponse } from './BaseRepository';
import type { DoerMoodBodyRequest, DoerMoodResponse, ProvidedServiceArchiveStatQuery, ProvidedServiceArchiveStatResponse } from './Models/Doer';

export class DoerRepository extends BaseRepository {
  /**
   * Список оказанных услуг мойщиком
   * 
   * @param params 
   * @returns 
   */
  getProvidedServiceArchiveStat(params: ProvidedServiceArchiveStatQuery): AsyncResponse<ProvidedServiceArchiveStatResponse> {
    return this.client.get('doer/get-data', { params });
  }

  /**
   * Отправить настроение за смену
   * 
   * @param body 
   * @returns 
   */
  postMood(body: DoerMoodBodyRequest): AsyncResponse<DoerMoodResponse> {
    return this.client.post('doer/mood', body);
  }

  /**
   * Получить информацию о текущем настроении и дате когда можно будет оценить еще раз
   * 
   * @returns 
   */
  getMood(): AsyncResponse<DoerMoodResponse> {
    return this.client.get('doer/mood');
  }
}