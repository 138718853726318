import { LocalizationDictionary } from '../types';

export default {
  locale: 'Русский',
  views: {
    // Всплывающие и модальные окна настроек (профиля и приложения)
    // NOTE: объединены несколько компонентов
    settings: {
      user_popover_title: 'Профиль пользователя',
      settings_modal_title: 'Настройки',
      settings_popover_title: '@:views.settings.settings_modal_title',
    },
  },
  components: {
    error_block: {
      btn_reload: 'Повторить попытку',
    },

    spinner: {
      loading: 'Загрузка...',
    },

    //#region Settings (& App services)
    app_settings: {
      label_input_cid: 'Введите номер вашего Core ID',
      label_input_language: 'Язык приложения',
      btn_apply_cid: 'Применить указанный номер',
      label_network_speed: 'Скорость интернет-соединения',
      label_number_recognition: 'Автоматическое распознования номера',
      description_number_recognition: 'В связи с внешними факторами cистема не гарантирует 100% распознавание регистрационного номера автомобиля',
      label_preentry: 'Предварительная запись',
      description_preentry: 'В случае необходимости вы можете отключить функцию предварительной записи',
      btn_support_chat: 'Написать в поддержку',
      btn_clear_cache: 'Сбросить кэш',
      btn_view_privacy_policy: 'Посмотреть политику',

      alert_clear_cache_header: 'Сброс кэша',
      alert_clear_cache_message: 'При сбросе удалятся все полученные с сервера данные.',
      alert_clear_cache_btn_confirm: 'Сбросить',
    },
    user_settings: {
      fullname_label: 'Администратор смены',
      btn_exit: 'Выйти',
      alert_exit_header: 'Подтвердить выход',
      alert_exit_message: 'После выхода из аккаунта вы будете перенаправлены на страницу входа',
      alert_exit_btn_confirm: 'Выйти',
    },
    login_form: {
      input_login: 'Логин',
      input_password: 'Пароль',
      btn_login: 'Войти',
      btn_login_loading: 'Загружаем...',
      message_success_logged_in: 'Успешный вход!',
      error_logged_in_incorrect_login_or_password: 'Проверьте правильность имени пользователя и пароля',
      header_error_logged_in_incorrect_login_or_password: 'Неверные учетные данные',
      header_error_not_authorized: 'Не авторизован',
      alert_coreid_header: 'Введите ваш Core ID',
      alert_coreid_message: 'Core ID можно найти в смс-сообщении, в котором находятся логин и пароль для входа в приложение',
      alert_btn_open_settings: 'Ввести Core ID',
    },
    //#endregion Settings (& App services)
  },
  composables: {
    alert: {
      confirm_default_btn_cancel: 'Отменить',
      confirm_default_btn_confirm: 'Подтвердить',
    },

    visit_timings: {
      progress_text_wait: 'Ожидание',
      progress_text_completed: 'Выполнен',
      progress_text_time_over: 'Время вышло',
      progress_text_time_left: 'Осталось: ~{left}',
    },

    visit_badges: {
      label_closed: 'Закрыт',
      label_finished: 'Выполнен',
      label_preentry: 'По записи',
      label_opened: 'Открыт',
      label_canceled: 'Отменен',
      label_paid: 'Оплачен',
      label_not_paid: 'Не оплачен',

      // Временно отключен
      label_not_sync: 'Не синхронизирован',
    },

    app_check_update: {
      open_app_store_error: 'Не удалось открыть магазин',
      open_app_store_error_not_supported: 'Не поддерживается на данной платформе',
      alert_info_header: 'Обновите приложение',
      alert_info_message: 'Мы обновили приложение, чтобы пользоваться Core12 стало еще проще.',
      alert_info_btn_update: 'Обновить',
      alert_info_btn_update_skip: 'Обновить позже',
    },
  },
  share: {
    plur_order: 'заказов | заказ | заказа | заказов',
    plur_points: 'бонусов | бонус | бонуса | бонусов',
  }
} as LocalizationDictionary;