import type { MessagePayload } from 'firebase/messaging';
import type { PushNotificationSchema } from '@capacitor/push-notifications';
import type { NotificationData } from '@/composables/notifications';

/**
 * Конвертирует данные push-уведомления то Capacitor плагина в общий формат
 * 
 * @param notification
 */
export function capPushNotificationSchemaToNotificationData(notification: PushNotificationSchema): NotificationData {
  const data = notification.data || {};
  
  return {
    id:       notification.id,
    title:    notification.title || data.title, // fix: для открываемых уведомлений по тапу
    subtitle: notification.subtitle,
    body:     notification.body  || data.body,  // fix: для открываемых уведомлений по тапу
    image:    undefined, // TODO: добавить изображение
    badge:    notification.badge, // По сути только на iOS
    data:     data,
    meta:     { /** Empty */ },
    createAt: new Date(),
  };
}

/**
 * Конвертирует уведомление от Firebase в общий формат уведомления
 * 
 * @param payload 
 * @returns 
 */
export function webFirebaseMessagePayloadToNotificationData(payload: MessagePayload): NotificationData {
  const notification = payload.notification || {};

  return {
    id:       payload.messageId,
    title:    notification.title,
    subtitle: undefined, // В вебе такого нет
    body:     notification.body,
    image:    notification.title,
    badge:    undefined,
    data:     payload.data,
    meta:     { /** Empty */ },
    createAt: new Date(),
  };
}