import Dexie from 'dexie';
import md5 from 'blueimp-md5';
import { CacheQuery } from './Tables/CacheQuery';
import { KeyValue } from './Tables/KeyValue';

export const DB_VERSION = 3;

export class AppDB extends Dexie {  
  public cacheQuery: Dexie.Table<CacheQuery, string>;
  public keyValue: Dexie.Table<KeyValue, string>;
  
  constructor (protected domain: string) {
    super(domain);

    this.version(DB_VERSION).stores({
      cacheQuery: 'key, updated',
      savedCarRequest: '++_id, carId, number, action',
      savedVisitRequest: '++_id, visitId, action, [visitId+action]',
      keyValue: 'key',
    });

    this.cacheQuery = this.table("cacheQuery");
    this.keyValue = this.table("keyValue");
  }

  /**
   * Сгенерирует ключ на основе переданных частей
   * 
   * @param partsKey 
   * @returns 
   */
  generateCacheKey(...partsKey: any[]): string {
    const key = JSON.stringify(partsKey);
    return md5(key, this.domain);
  }
}
