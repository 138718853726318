
import { defineComponent, PropType } from 'vue';
import { useSettingsApp, useSettingsUser } from '@/composables/useSettingsApp';
import { networkStatusColor } from '@/helpers/network';
import { useMetrica } from '@/composables/useMetrica';
import { isTabletDown } from '@/helpers/adaptive';

export default defineComponent({
  props: {
    title: {
      type: String as PropType<string>,
    }
  },
  setup() {
    const { emitEvent } = useMetrica();
    const { openSettings: openSettingsApp } = useSettingsApp();
    const {
      openUserPopover,
      iconLabel: userIconLabel,
      user,
      fullName,
    } = useSettingsUser();

    function openSettings(ev?: CustomEvent) {
      emitEvent('app/settings/open');
      return openSettingsApp(ev);
    }

    function openUserInfo(ev?: CustomEvent) {
      emitEvent('app/user/view');
      return openUserPopover(ev);
    }

    return {
      openSettings,
      networkStatusColor,
      openUserInfo,
      userIconLabel,
      user,
      isTabletDown,
      fullName,
    };
  }
});
