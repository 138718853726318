import { modalController, popoverController } from '@ionic/vue';
import SettingsModal from '@/views/settings/SettingsModal.vue';
import SettingsPopover from '@/views/settings/SettingsPopover.vue';
import UserPopover from '@/views/settings/UserPopover.vue';
import { isTabletUpScreenWidth } from '@/helpers/adaptive';
import { useUser } from './useUser';

export function useSettingsApp() {
  /** Открвает вид настройек в зависимости от устройтва */
  function openSettings(ev?: Event) {
    if (isTabletUpScreenWidth()) {
      openSettingsPopover(ev); // async
    } else {
      openSettingsModal(); // async
    }
  }

  /** Открыть настройки в модальном окне */
  async function openSettingsModal() {
    const modal = await modalController.create({
      component: SettingsModal,
    });

    modal.present(); // async

    return modal;
  }

  /** Открыть настройки в выпадающем блоке */
  async function openSettingsPopover(ev?: Event) {
    const popover = await popoverController.create({
      cssClass: 'core-settings-popover',
      component: SettingsPopover,
      event: ev,
      // translucent: true,
    });

    popover.present(); // async
    return popover;
  }

  return {
    openSettings,
    openSettingsModal,
    openSettingsPopover,
  };
}

export function useSettingsUser() {
  const {
    user,
    iconLabel,
    fullName,
  } = useUser();

  /** Открыть настройки в выпадающем блоке */
  async function openUserPopover(ev?: Event) {
    const popover = await popoverController.create({
      cssClass: 'core-settings-popover',
      component: UserPopover,
      event: ev,
    });

    popover.present(); // async
    return popover;
  }

  return {
    user,
    iconLabel,
    fullName,
    openUserPopover,
  };
}