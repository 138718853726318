import { getDateCleanTime } from '@/helpers/datetime';
import moment from 'moment';
import { computed, ref, Ref, toRefs } from 'vue';
import { useStore } from './useApp';
import { find, groupBy, keyBy, map, get, toNumber, reduce } from 'lodash';
import { useToast } from './useToast';

//#region Эмоции (статусы настроения)
import smileGood from '@/icons/doer-smiles/smile-good.svg';
import smileOk from '@/icons/doer-smiles/smile-ok.svg';
import smileSoso from '@/icons/doer-smiles/smile-soso.svg';
import smileBad from '@/icons/doer-smiles/smile-bad.svg';
import smileVeryBad from '@/icons/doer-smiles/smile-verybad.svg';
import { DoerProvidedServiceStatItem } from '@/repositories/Models/Doer';

export const MOOD_DEFAULT = [
  {
    value: 5,
    color: '#0AD34E',
    iconSrc: smileGood,
  },
  {
    value: 4,
    color: '#54B492',
    iconSrc: smileOk,
  },
  {
    value: 3,
    color: 'rgba(229, 229, 229, 0.5)',
    iconSrc: smileSoso,
  },
  {
    value: 2,
    color: '#E7B274',
    iconSrc: smileBad,
  },
  {
    value: 1,
    color: '#E78774',
    iconSrc: smileVeryBad,
  },
];
//#endregion

export interface RangeDataOptions {
  startTime: Ref<Date>;
  endTime: Ref<Date>;
}

export type DoerProvidedServicesStatOptions = RangeDataOptions;
// export interface DoerProvidedServicesStatOptions extends RangeDataOptions {
// }

export interface DoersStatRangeTabsOptions {
  model: RangeDataOptions;
  choiceCustom?: () => void;
}

export interface RangeTabData {
  name: string;
  startTime?: Date;
  endTime?: Date;
  label: string;
  setCurrent(): void;
}

export interface DoerCarGroupInformation {
  _key: string;

  carNumber: string;
  finishedDate: string;
  processedDate: string;
  addrImage: string;
  nameCategory: string;
  payed: number;
  totalReward: number;
  provideServicesInformation: DoerProvidedServiceStatItem[];
}

export function useDoerProvidedServicesStat(options: DoerProvidedServicesStatOptions) {
  const store = useStore();

  const doerStat = store.doer.stat;

  async function loadStat() {
    const startTime = moment(options.startTime.value).format('YYYY-MM-D');
    const endTime = moment(options.endTime.value).format('YYYY-MM-D');

    await store.doer.getVisitsArchiveStat({
      startTime,
      endTime,
    });
  }

  const providedServices = computed(() => doerStat.items);
  const total = computed(() => doerStat.total);

  // NOTE: Сгруппировать по заказу напряму не могу,
  // т.к. не возвращается идентификатор заказа.
  const provideServicesGroupByCar = computed(() => {
    const groupItems = groupBy(doerStat.items, item => `${item.carNumber}|${item.finishedDate}`);
    const groups: DoerCarGroupInformation[] = map(groupItems, (items: DoerProvidedServiceStatItem[]) => {
      const first = get(items, '0') as DoerProvidedServiceStatItem;
      const totalReward = reduce(items, (total, item) => total + toNumber(item.reward), 0);

      return {
        _key: `${first.carNumber}|${first.finishedDate}`,
        carNumber: first.carNumber,
        finishedDate: first.finishedDate,
        processedDate: first.processedDate,
        addrImage: first.addrImage,
        nameCategory: first.nameCategory,
        payed: toNumber(first.payed),
        totalReward,
        provideServicesInformation: items,
      };
    });

    return groups;
  });

  return {
    loadStat,
    providedServices,
    total,
    provideServicesGroupByCar,
  };
}

/**
 * Генерирует временные вкладки для отображения статистики, за определенный диапазон
 * 
 * @param options 
 * @returns 
 */
export function useDoersStatRangeTabs(options: DoersStatRangeTabsOptions) {
  const current = ref<RangeTabData|null>(null);

  function createTab(name: string, startTime: Date, endTime: Date, label: string) {
    const tab: RangeTabData = {
      name,
      startTime,
      endTime,
      label,
      setCurrent: () => {
        current.value = tab;

        options.model.startTime.value = new Date(startTime);
        options.model.endTime.value = new Date(endTime);
      },
    };

    return tab;
  }

  function createBetweenTab(name: string, label: string) {
    const tab = {
      name,
      label,
      setCurrent() {
        current.value = tab;
        options.choiceCustom?.call(null);
      }
    };

    return tab;
  }

  function generateRangeDateTabs(): RangeTabData[] {
    const now = getDateCleanTime(new Date());
    const prevDate = moment(now).add(-1, 'day').toDate();
    const startWeek = moment(now).startOf('week').toDate();
    const startMounth = moment(now).startOf('month').toDate();

    return [
      createTab('today', now, now, 'Сегодня'),
      createTab('yesterday', prevDate, prevDate, 'Вчера'),
      createTab('week', startWeek, now, 'Эта неделя'),
      createTab('mounth', startMounth, now, 'Этот месяц'),
      createBetweenTab('between', 'Выбрать период'),
    ];
  }

  const rangeDateTabs = computed(generateRangeDateTabs);
  const rangeDateTabsKeyByName = computed(() => keyBy(rangeDateTabs.value, 'name'));

  return {
    rangeDateTabs,
    rangeDateTabsKeyByName,
    current,
  };
}

export function useDoerMood() {
  const store = useStore();
  const toast = useToast();
  
  const loadingMood = ref(false);
  const loadingUpdateMood = ref(false);
  const formSubmittedFlag = ref(false);

  const hasMood = computed(() => !!store.doer.mood.mood);

  // Настроение при инициализации уже заданно (без отправки формы)
  const isStartHasMood = computed(() => {
    return hasMood.value && !formSubmittedFlag.value;
  });

  // Оценку можно поставить, только, если дата next - уже прошла
  const isSubmitMood = computed(() => {
    return moment(store.doer.mood.nextDate)
      .isSameOrBefore(moment());
  });

  const moodExtendInfo = computed(() => {
    return find(MOOD_DEFAULT, i => i.value === store.doer.mood.mood) || null;
  });

  async function loadMood() {
    loadingMood.value = true;

    try {
      await store.doer.getMood();
    } catch (e: any) {
      toast.error(e);
    } finally {
      loadingMood.value = false;
    }
  }

  async function submitMood(mood: number) {
    loadingUpdateMood.value = true;

    try {
      await store.doer.setMood({ mood });
      formSubmittedFlag.value = true;
    } catch (e: any) {
      toast.error(e);
    } finally {
      loadingUpdateMood.value = false;
    }
  }

  return {
    ...toRefs(store.doer.mood),
    isForsedMood: store.doer.isForsedMood,
    loadMood,
    loadingMood,
    loadingUpdateMood,
    submitMood,
    hasMood,
    isSubmitMood,
    formSubmittedFlag,
    isStartHasMood,
    moodExtendInfo,
  };
}