
import { defineComponent, computed, ref, watch } from 'vue';
import {
  getCalendarGrid,
  convertToDateAndCleanTime,
  CalendarDayInfo
} from '@/helpers/datetime';

export default defineComponent({
  emits: ['update:modelValue', 'click-day'],

  props: {
    mounth: {
      type: Number,
      required: true,
    },
    year: {
      type: Number,
      required: true,
    },
    modelValue: {
      type: null,
    },
    isBeetween: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    function prepareValue(value: any): Date|Date[]|null {
      if (!value) return null;

      if (props.isBeetween) {
        if (!Array.isArray(value)) {
          return [];
        }

        let preparedValue: Date[] = [];
        if (value[0]) {
          preparedValue[0] = convertToDateAndCleanTime(value[0]);

          if (value[1]) {
            preparedValue[1] = convertToDateAndCleanTime(value[1]);

            if (preparedValue[1] < preparedValue[0]) {
              preparedValue = preparedValue.reverse();
            }
          }
        }

        return preparedValue;
      }
      
      return convertToDateAndCleanTime(value);
    }

    const innerValue = computed<null|Date|Date[]>({
      get() {
        return lazyValue.value;
      },
      set(value) {
        lazyValue.value = prepareValue(value);
        emit('update:modelValue', innerValue.value);
      },
    });
    
    const mounthGridInfo = computed(() => {
      return getCalendarGrid(props.mounth, props.year);
    });

    function onClickDate(dateInfo: CalendarDayInfo) {
      if (props.isBeetween) {
        let newValue: Date[] = Array.isArray(innerValue.value) ? [...innerValue.value] : [];
        if (newValue.length >= 2) {
          newValue = [];
        }

        newValue.push(dateInfo.dateObject);
        innerValue.value = newValue;
      } else {
        innerValue.value = dateInfo.dateObject;
      }

      emit('click-day', dateInfo);
    }

    const lazyValue = ref<null|Date|Date[]>(prepareValue(props.modelValue));
    watch(() => props.modelValue, value => lazyValue.value = prepareValue(value));

    const innerValueTimeshtamp = computed<number|null>(() => {
      return (innerValue.value instanceof Date)
        ? innerValue.value.getTime()
        : null // Если значение не задано или between
      ;
    });

    const innerValueBeetweenTimeshtamp = computed(() => {
      if (!props.isBeetween || !Array.isArray(innerValue.value)) return [];
      
      return [
        (innerValue.value[0] instanceof Date) ? innerValue.value[0].getTime() : null,
        (innerValue.value[1] instanceof Date) ? innerValue.value[1].getTime() : null,
      ];
    });

    /**
     * Возвращает Selected классы, для текущей ячейки
     * 
     * @param dateInfo 
     * @param classNameBasePart 
     */
    function getSelectedClasses(dateInfo: CalendarDayInfo, classNameBasePart: string): { [className: string]: boolean; } {
      if (props.isBeetween) {
        const [startTs, endTs] = innerValueBeetweenTimeshtamp.value;

        const isStart = startTs === dateInfo.timestamp;
        const isEnd = endTs === dateInfo.timestamp;
        const selected = isStart || isEnd;
        
        return {
          [`${classNameBasePart}--selected`]: selected,
          [`${classNameBasePart}--selected-single`]: selected && Boolean((!startTs && endTs) || (startTs && !endTs)),
          [`${classNameBasePart}--selected-start`]: isStart,
          [`${classNameBasePart}--selected-end`]: isEnd,
        };
      }

      const selected = dateInfo.timestamp === innerValueTimeshtamp.value;

      return {
        [`${classNameBasePart}--selected`]: selected,
      };
    }

    /**
     * Проверяет является ли ячейка промедуточный
     * Актуально, только для режима Beetween.
     * 
     * @param dateInfo 
     */
    function isIntermediate(dateInfo: CalendarDayInfo): boolean {
      if (!props.isBeetween) return false;

      const curr = dateInfo.timestamp;
      const [begin, end] = innerValueBeetweenTimeshtamp.value;

      return !!begin && !!end
        && curr > begin
        && curr < end;
    }

    return {
      mounthGridInfo,
      onClickDate,
      getSelectedClasses,
      isIntermediate,
    };
  }
});
