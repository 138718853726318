import { errorToMessageString } from '@/helpers/error';
import { toastController } from '@ionic/vue';
import { ToastOptions } from '@ionic/core';

async function toastBase(e: any, options: ToastOptions, duration = 3500) {
  let message = errorToMessageString(e);

  if (message.toLocaleLowerCase() === 'network error') {
    message = 'Проверьте подключения к интернету';

    if (!options.header) {
      options.header = 'Нет сети';
    }
  }

  const toast = await toastController.create({
    color: 'primary',
    position: 'top',
    ...options,
    message, duration,
  });

  return toast.present();
}

export async function errorMessageToast(e: any, duration = 3500, options: ToastOptions = {}) {
  return await toastBase(e, {
    // color: 'danger',
    // По дизайну приложения, цвет ошибок совпадает с основным цветом (ораньжевым)
    color: 'primary',
    buttons: [
      {
        role: 'cancel',
        icon: 'core-close',
        cssClass: 'toast-icon-big'
      }
    ],
    ...options
  }, duration);
}

export async function primaryToast(e: any, duration = 3500, options: ToastOptions = {}) {
  return await toastBase(e, {
    color: 'primary',
    keyboardClose: true,
    ...options
  }, duration);
}

export async function successToast(e: any, duration = 3500, options: ToastOptions = {}) {
  return await toastBase(e, {
    color: 'success',
    keyboardClose: true,
    buttons: [
      {
        role: 'cancel',
        icon: 'core-close',
        cssClass: 'toast-icon-big'
      }
    ],
    ...options
  }, duration);
}

export async function warningToast(e: any, duration = 3500, options: ToastOptions = {}) {
  return await toastBase(e, {
    color: 'warning',
    keyboardClose: true,
    ...options
  }, duration);
}

export default {
  error: errorMessageToast,
  primary: primaryToast,
  success: successToast,
  warning: warningToast,
};
