
import { defineComponent, PropType, ref, computed } from 'vue';
import type { DoerCarGroupInformation } from '@/composables/doer';
import CollapseTransition from '@/components/core/CollapseTransition.vue';
import CarNumber from '@/components/car/CarNumber.vue';
import carNoPhoto from '@/img/car-no-image.svg';
import moment from 'moment';
import VisitGroupProvideServiceItem from '@/components/doer/VisitGroupProvideServiceItem.vue';

export default defineComponent({
  components: {
    CarNumber,
    CollapseTransition,
    VisitGroupProvideServiceItem,
  },

  props: {
    groupInfo: {
      type: Object as PropType<DoerCarGroupInformation>,
      required: true,
    },
  },

  setup(props) {
    const expanded = ref(false);

    function toogleExpand() {
      expanded.value = !expanded.value;
    }

    const datetimeFormatted = computed(() => {
      const begin = moment(props.groupInfo.processedDate).format('HH:mm');
      const end = moment(props.groupInfo.finishedDate).format('HH:mm');
      const date = moment(props.groupInfo.finishedDate).format('DD.MM');
      return `${date}, ${begin} - ${end}`;
    });

    return {
      expanded,
      toogleExpand,
      carNoPhoto,
      datetimeFormatted,
    };
  },
});
