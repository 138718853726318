/**
 * Ограничит текст указанной длинной
 * Обрезает строку не по количеству символов а по словам которые помещаются в указанную длинну
 * 
 * @param text текст который надо ограничить
 * @param limitLenght максимально возможная длинна текста + append
 * @param append значение которое добавиться в конец обрезанной строки (например: ...)
 */
export function limitTextLength(text: any, limitLenght: number, append = ' ...'): string {
  text = text ? String(text) : '';

  if (text.length <= limitLenght) {
    return text;
  }

  const textWords = text.split(' ');
  let computed = [];
  let wordsLength = 0;

  for (let w of textWords) {
    wordsLength += w.length;
    if (wordsLength > limitLenght) break;
    computed.push(w);
  }

  return computed.join(' ') + append;
}

/**
 * Заменит все переносы строк на теги переноса
 * @param value строка с переносами \n или \r\n
 * @param newLineTag тег переноса на новую строку (по умолчанию: <br>)
 */
export function nl2br(value: string, newLineTag = '<br>'): string {
  return value.replace(/\r?\n/g, newLineTag);
}

/**
 * Функция распознает и заменит все ссылки на <a> теги
 * 
 * @param value 
 * @returns 
 */
 export function textLinkToHtmlLink(value: string) {
  const pattern = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z()]{1,6}\b([-a-zA-Z0-9()@:;%_+.~#?&//=]*)/g;
  let preparedValue = value;

  value.match(pattern)?.forEach(elem => {
    if (elem.match(/^https?:\/\//i)) {
      preparedValue = preparedValue.replace(elem, '<a href="$&" target="_blank">$&</a>');
    } else {
      preparedValue = preparedValue.replace(elem, '<a href="//$&" target="_blank">$&</a>');
    }
  });

  return preparedValue;
}

/**
 * Удалит тэги
 * @param html HTML текст
 */
export function removeTags(html: string): string {
  return html.replace(/<\/?.+?>/g, '');
}

const RIGHT_NUMBER_CASES = [2, 0, 1, 1, 1, 2];

/**
 * Вернет правильную метку на основе числа
 * 
 * @param num число
 * @param label1 метка в единственном числе (один товар)
 * @param label2 метка (два товара)
 * @param label5 метка (пять товаров)
 */
export function swichLabelNumber(num: number, label1: string, label2: string, label5: string) {
  if (!Number.isInteger(num)) {
    num = Math.ceil(num); // FIXME: bad fix
  }

  const titles = [label1, label2, label5];
  const labelIndex = (num % 100 > 4 && num % 10 < 20)
    ? 2 : RIGHT_NUMBER_CASES[ (num % 10 < 5) ? num % 10 : 5 ];
  return titles[ labelIndex ];
}

/**
 * Преобразует номер телефона в 11-ти значный Российский номер
 * в формат +7XXXXXXXXXX
 * 
 * @throws {Error}
 * 
 * @param phone телефон
 * @param def если указать значение отличное от undefined, то будет возыращаться в случае ошибки, иначе исключение
 * @returns 
 */
export function phonePrepare<T>(phone: any, def?: T): string|T {
  let prepared = String(phone)
    .replace(/\D/g, '')
    .replace(/^8/, '7');

  if (prepared.length === 10 && prepared[0] === '9') {
    prepared = `7${prepared}`;
  }

  if (prepared.length !== 11) {
    if (def !== undefined) return def;

    throw new Error('Некорректная длинна номера телефона');
  }
  
  if (prepared[0] === '7') {
    prepared = `+${prepared}`;
  }

  return prepared;
}

/**
 * Более мягкая обработка телефона
 */
export function softPhonePrepare<T>(phone: any, def?: T): string|T|null {
  let prepared = String(phone).replace(/\D/g, '');

  if (prepared.length < 5) return def || null;

  if (prepared.length === 11) {
    prepared = prepared.replace(/^8/, '7');

    if (prepared[0] === '7') {
      prepared = `+${prepared}`;
    }
  } else if (prepared.length === 10) {
    if (prepared[0] === '9') {
      prepared = `+7${prepared}`;
    }
  }

  return prepared;
}

/**
 * Обработает номер телефона и преобразует в формат для хранения в бд.
 * 
 * Исправляет проблему связанную с рассылкой СМС сообщений. 
 * В случае, если телефон указан с +7 отображение корректное, но при отправке СМС возникает ошибка.
 * В форматах 7XXX... и 8XXX... такой проблемы не наблюдается.
 * 
 * fix: В БД старого приложения номера хранились в формате 10-ти знаков, без 7 или 8. 
 * Для корректного отображения приводим к формату 9001112233
 * 
 * link: https://core12guest.slack.com/archives/C029FGGNWBF/p1637058490012400
 * link fix: https://core12guest.slack.com/archives/C01SYGJT0CQ/p1645434029124879?thread_ts=1645432813.183359&cid=C01SYGJT0CQ
 * 
 * @param phone 
 */
export function preparePhoneCore12LegacyFormat(phone: any): string {
  let prepared = String(phone).replace(/\D/g, '');

  if (prepared.length === 11) {
    prepared = prepared.replace(/^(7|8)/, '');
  }

  return prepared;
}
